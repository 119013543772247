import { ColumnDef, ColumnFiltersState, PaginationState, Row, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getTrackerServiceLogsTableSettings, saveTrackerServiceLogsTableSettings, trackerServiceLogsTableDefaults } from "../../services/localStorage";
import { deleteTrackerServiceLog, fetchTrackerServiceLogs, fetchTrackerServicePdfUrl } from "../../services/trackerServiceLogs";
import MoreIcon from "../../svgs/MoreIcon";
import addOrUpdate from "../../util/addOrUpdate";
import { isSuper } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import { formatUTCDateToLocal } from "../../util/dateUtils";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { printFixedTemp, printTemp, printTempUnit } from "../../util/formatUnits";
import { getTableFilters } from "../../util/urlParamFilters";
import { PrimaryBtn } from "../Buttons";
import CreateTrackerServiceLogModal from "../CreateTrackerServiceLogModal";
import DeleteModal from "../DeleteModal";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type TrackerServiceLogs = {
  id: number;
  trackerId: string;
  servicedBy: string;
  serviceDate?: Date;
  roomTemperature: number;
  actions: string;
  notes: string;
  certificateUrl?: string;
  warning?: string;
};

const TrackerServicesTable: FC<any> = ({ trackerId, onSuccess }) => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "serviceDate", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getTrackerServiceLogsTableSettings() ? getTrackerServiceLogsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If trackerId is not empty add filters to only show service logs for the specific trackerId
  const trackerFilter = (filters: any) => {
    if (trackerId == null) return filters;

    const filtersCopy = JSON.parse(JSON.stringify(filters));
    const filter = filtersCopy.find((x: any) => x.id === "trackerId");
    if (filter) filter.value = { type: "string", value: trackerId };
    else filtersCopy.push({ id: "trackerId", value: { type: "string", value: trackerId } });
    return filtersCopy;
  };

  const getTrackerServices = () => {
    setDataLoading(true);
    fetchTrackerServiceLogs(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(trackerFilter(columnFiltersDebounced)) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    fetchTrackerServiceLogs(source, { orderBy: sorting, filters: getTableFilters(trackerFilter(columnFiltersDebounced)) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Service Logs List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = ["Serviced By", "Service Date", `Room Temperature (${printTempUnit()})`, "Actions", "Certificate URL", "Notes"];

    if (trackerId == null) {
      headers.unshift("Tracker ID");
    }

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.servicedBy,
          row.serviceDate != null ? formatUTCDateToLocal(row.serviceDate, "DATE_FULL") : "",
          printFixedTemp(row.roomTemperature, 1),
          row.actions,
          row.certificateUrl,
          row.notes,
        ];

        if (trackerId == null) {
          rows.unshift(row.trackerId);
        }

        return rows;
      }, []),
    ];
  };

  const updateTable = (newData: any) => {
    setData((prev: any) => addOrUpdate(prev, newData, "id"));
    setSorting((prev) => [...prev]); // This will trigger sorting recalculation
    if (onSuccess) onSuccess();
  };

  const viewPdf = (service: any) => {
    if (service.id != null) {
      fetchTrackerServicePdfUrl(source, service.id).then((url) => {
        window.open(url, "_blank");
      });
    }
  };

  const downloadPdf = (service: any) => {
    if (service.id != null) {
      fetchTrackerServicePdfUrl(source, service.id).then((url) => {
        const a = document.createElement("a");
        a.href = url;
        a.download = `BM-Service-Certificate-${service.trackerId}-${formatUTCDateToLocal(service.serviceDate, "dd-LL-yyyy")}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
    }
  };

  const columns = React.useMemo<ColumnDef<TrackerServiceLogs>[]>(
    () => [
      ...(isSuper()
        ? [
            {
              id: "actions",
              header: "",
              enableColumnFilter: false,
              enableSorting: false,
              enableHiding: false,
              cell: ({ row }: { row: Row<TrackerServiceLogs> }) => {
                return (
                  <Tooltip
                    maxWidth="none"
                    theme="binary-no-padding"
                    content={
                      <MenuList>
                        <DangerMenuButton
                          onClick={() => {
                            setSelectedRow(row.original);
                            setDeleteModalOpen(true);
                          }}
                        >
                          Delete Service Log
                        </DangerMenuButton>
                      </MenuList>
                    }
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                    trigger="click"
                    placement="bottom-start"
                  >
                    <MoreIconContainer>
                      <MoreIconSize>
                        <MoreIcon fill={color.font[2]} />
                      </MoreIconSize>
                    </MoreIconContainer>
                  </Tooltip>
                );
              },
              size: 65,
              minSize: 65,
            },
          ]
        : []),
      ...(trackerId == null
        ? [
            {
              header: "Tracker ID",
              accessorKey: "trackerId",
              meta: {
                filterType: "string",
              },
              filterFn: undefined,
              size: 250,
            },
          ]
        : []),
      {
        header: "Serviced By",
        accessorKey: "servicedBy",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Service Date",
        accessorKey: "serviceDate",
        cell: (props: any) => (props.getValue() ? formatUTCDateToLocal(props.getValue(), "DATE_MED") : ""),
        meta: {
          filterType: "dateRange",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Room Temperature",
        accessorKey: "roomTemperature",
        cell: (props: any) => printTemp(props.getValue(), 1),
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Actions",
        accessorKey: "actions",
        cell: (props: any) => (
          <Tooltip
            content={props
              .getValue()
              .split(",")
              .map((action: string) => (
                <p>{action}</p>
              ))}
          >
            <div
              style={{
                cursor: "not-allowed",
                userSelect: "none",
              }}
            >
              <span style={{ textDecoration: "underline", fontWeight: 500, cursor: "pointer" }}>{props.getValue()}</span>
            </div>
          </Tooltip>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Notes",
        accessorKey: "notes",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "Certificate URL",
        accessorKey: "certificateUrl",
        cell: (props: any) =>
          props.getValue() === "Generating PDF..." ? (
            <Tooltip content="Please refresh the page to view the certificate">
              <p>Generating PDF...</p>
            </Tooltip>
          ) : props.getValue() === "Error" ? (
            <Tooltip content="Error generating PDF">
              <p>Error</p>
            </Tooltip>
          ) : props.getValue() !== "" ? (
            <p>
              <span onClick={() => viewPdf(props.row.original)} style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 500 }}>
                View
              </span>
              {" | "}
              <span onClick={() => downloadPdf(props.row.original)} style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 500 }}>
                Download
              </span>
            </p>
          ) : (
            <p>No Certificate</p>
          ),
        size: 180,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={getTrackerServices}
        fetchCsv={fetchCsv}
        defaultTableSettings={trackerServiceLogsTableDefaults}
        getTableSettings={getTrackerServiceLogsTableSettings}
        saveTableSettings={saveTrackerServiceLogsTableSettings}
        dataTypeName="Service Logs"
        emptyDataMsg="Create a new service log above to get started"
        TableButtons={
          <div>
            {isSuper() && (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedRow(null);
                      setCreateModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedRow(null);
                        setCreateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {createModalOpen && (
        <CreateTrackerServiceLogModal trackerId={trackerId} onSuccess={updateTable} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Service Log"
          body={
            <>
              <span>Are you sure you want to delete this service log?</span>
            </>
          }
          successMsg="Service log deleted successfully"
          onDelete={() => {
            setData((prev: any) => prev.filter((row: any) => row.id !== selectedRow.id));
            if (onSuccess) onSuccess();
          }}
          onClose={() => setSelectedRow(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteTrackerServiceLog}
          serviceParams={[selectedRow.id]}
        />
      )}
    </>
  );
};

export default TrackerServicesTable;
