import { toCelsius, toKilopascals, toMetersPerSecond } from "./formatUnits";
import isEmpty from "./isEmpty";
import { trimString } from "./strings";
import { isSuper } from "./checkRole";
import { isBinaryBeer } from "./checkDomain";

const formatTrackerSettings = (formData: any) => {
  const formattedData: any = {};

  // Invert the adcAlertMode for input 1 if inputMode is NTC Thermistor because the adc values are inverted on the Kraken for the temperature input
  formattedData.adcAlertMode1 =
    formData.adcAlertMode1 && formData.adcAlertMode1.value !== undefined
      ? formData.input1Mode.value === "NTC Thermistor"
        ? !formData.adcAlertMode1.value
        : formData.adcAlertMode1.value
      : undefined;
  formattedData.adcAlertMode2 = formData.adcAlertMode2 && formData.adcAlertMode2.value !== undefined ? formData.adcAlertMode2.value : undefined;
  formattedData.adcAlertMode3 = formData.adcAlertMode3 && formData.adcAlertMode3.value !== undefined ? formData.adcAlertMode3.value : undefined;
  formattedData.adcAlertMode4 = formData.adcAlertMode4 && formData.adcAlertMode4.value !== undefined ? formData.adcAlertMode4.value : undefined;
  formattedData.adcAlertMode5 = formData.adcAlertMode5 && formData.adcAlertMode5.value !== undefined ? formData.adcAlertMode5.value : undefined;
  formattedData.adcAlertMode6 = formData.adcAlertMode6 && formData.adcAlertMode6.value !== undefined ? formData.adcAlertMode6.value : undefined;
  formattedData.adcAlertMode7 = formData.adcAlertMode7 && formData.adcAlertMode7.value !== undefined ? formData.adcAlertMode7.value : undefined;
  if (formData.adcAlertValue1 && formData.input1Mode && formData.input1Mode.value === "NTC Thermistor")
    formattedData.adcAlertValue1 = toCelsius(formData.adcAlertValue1);
  else formattedData.adcAlertValue1 = formData.adcAlertValue1;
  formattedData.adcAlertValue2 = formData.adcAlertValue2 === "" ? undefined : formData.adcAlertValue2;
  if (formData.adcAlertValue3 && formData.input3Mode && formData.input3Mode.value === "Air Velocity")
    formattedData.adcAlertValue3 = toMetersPerSecond(formData.adcAlertValue3);
  else formattedData.adcAlertValue3 = formData.adcAlertValue3;
  if (formData.adcAlertValue4 && formData.input4Mode && formData.input4Mode.value === "Differential Pressure")
    formattedData.adcAlertValue4 = toKilopascals(formData.adcAlertValue4);
  else formattedData.adcAlertValue4 = formData.adcAlertValue4;
  formattedData.adcAlertValue5 = formData.adcAlertValue5 === "" ? undefined : formData.adcAlertValue5;
  formattedData.adcAlertValue6 = formData.adcAlertValue6 === "" ? undefined : formData.adcAlertValue6;
  formattedData.adcAlertValue7 = formData.adcAlertValue7 === "" ? undefined : formData.adcAlertValue7;
  formattedData.adcEnableAlert1 = formData.adcEnableAlert1;
  formattedData.adcEnableAlert2 = formData.adcEnableAlert2;
  formattedData.adcEnableAlert3 = formData.adcEnableAlert3;
  formattedData.adcEnableAlert4 = formData.adcEnableAlert4;
  formattedData.adcEnableAlert5 = formData.adcEnableAlert5;
  formattedData.adcEnableAlert6 = formData.adcEnableAlert6;
  formattedData.adcEnableAlert7 = formData.adcEnableAlert7;
  if (formData.input1Mode && formData.input1Mode.value === "NTC Thermistor") formattedData.adcSamplingMode1 = false;
  else formattedData.adcSamplingMode1 = formData.adcSamplingMode1 ? formData.adcSamplingMode1.value : undefined;
  // Set adcSamplingMode for input 2 to true if inputMode is CT Clamp to set sampling to MAX not AVERAGE in order to sample the peaks of the alternating current
  if (formData.input2Mode && formData.input2Mode.value === "CT Clamp") formattedData.adcSamplingMode2 = true;
  else formattedData.adcSamplingMode2 = formData.adcSamplingMode2 ? formData.adcSamplingMode2.value : undefined;
  if (formData.input3Mode && formData.input3Mode.value === "Air Velocity") formattedData.adcSamplingMode3 = false;
  else formattedData.adcSamplingMode3 = formData.adcSamplingMode3 ? formData.adcSamplingMode3.value : undefined;
  if (formData.input4Mode && formData.input4Mode.value === "Differential Pressure") formattedData.adcSamplingMode4 = false;
  else formattedData.adcSamplingMode4 = formData.adcSamplingMode4 ? formData.adcSamplingMode4.value : undefined;
  formattedData.adcSamplingMode5 = formData.adcSamplingMode5 ? formData.adcSamplingMode5.value : undefined;
  formattedData.adcSamplingMode6 = formData.adcSamplingMode6 ? formData.adcSamplingMode6.value : undefined;
  formattedData.adcSamplingMode7 = formData.adcSamplingMode7 ? formData.adcSamplingMode7.value : undefined;
  formattedData.assetId = trimString(formData.assetId);
  formattedData.assetTypeId = formData.assetType && formData.assetType.value ? formData.assetType.value : null;
  formattedData.assetTypeName = formData.assetType && formData.assetType.label ? formData.assetType.label : null;
  if (isSuper()) formattedData.enableMagnetWakeup = formData.enableMagnetWakeup;
  if (isSuper()) formattedData.enableSwitchWakeup = formData.enableSwitchWakeup;
  if (isSuper() && !isEmpty(formData.gpsRetryTime)) formattedData.gpsRetryTime = formData.gpsRetryTime;
  formattedData.id = formData.id;
  formattedData.input1Label = trimString(formData.input1Label);
  formattedData.input1Max = formData.input1Max === "" ? undefined : formData.input1Max;
  formattedData.input1Min = formData.input1Min === "" ? undefined : formData.input1Min;
  formattedData.input1Mode = formData.input1Mode && formData.input1Mode.value ? formData.input1Mode.value : undefined;
  formattedData.input1Unit = trimString(formData.input1Unit);
  formattedData.input2Label = trimString(formData.input2Label);
  formattedData.input2Max = formData.input2Max === "" ? undefined : formData.input2Max;
  formattedData.input2Min = formData.input2Min === "" ? undefined : formData.input2Min;
  formattedData.input2Mode = formData.input2Mode && formData.input2Mode.value ? formData.input2Mode.value : undefined;
  formattedData.input2Unit = trimString(formData.input2Unit);
  formattedData.input3Label = trimString(formData.input3Label);
  formattedData.input3Max = formData.input3Max === "" ? undefined : formData.input3Max;
  formattedData.input3Min = formData.input3Min === "" ? undefined : formData.input3Min;
  formattedData.input3Mode = formData.input3Mode && formData.input3Mode.value ? formData.input3Mode.value : undefined;
  formattedData.input3Unit = trimString(formData.input3Unit);
  formattedData.input4Label = trimString(formData.input4Label);
  formattedData.input4Max = formData.input4Max === "" ? undefined : formData.input4Max;
  formattedData.input4Min = formData.input4Min === "" ? undefined : formData.input4Min;
  formattedData.input4Mode = formData.input4Mode && formData.input4Mode.value ? formData.input4Mode.value : undefined;
  formattedData.input4Unit = trimString(formData.input4Unit);
  formattedData.input5Label = trimString(formData.input5Label);
  formattedData.input5Max = formData.input5Max === "" ? undefined : formData.input5Max;
  formattedData.input5Min = formData.input5Min === "" ? undefined : formData.input5Min;
  formattedData.input5Mode = formData.input5Mode && formData.input5Mode.value ? formData.input5Mode.value : undefined;
  formattedData.input5Unit = trimString(formData.input5Unit);
  formattedData.input6Label = trimString(formData.input6Label);
  formattedData.input6Max = formData.input6Max === "" ? undefined : formData.input6Max;
  formattedData.input6Min = formData.input6Min === "" ? undefined : formData.input6Min;
  formattedData.input6Mode = formData.input6Mode && formData.input6Mode.value ? formData.input6Mode.value : undefined;
  formattedData.input6Unit = trimString(formData.input6Unit);
  formattedData.input7Label = trimString(formData.input7Label);
  formattedData.input7Max = formData.input7Max === "" ? undefined : formData.input7Max;
  formattedData.input7Min = formData.input7Min === "" ? undefined : formData.input7Min;
  formattedData.input7Mode = formData.input7Mode && formData.input7Mode.value ? formData.input7Mode.value : undefined;
  formattedData.input7Unit = trimString(formData.input7Unit);
  formattedData.kegTypeId = formData.kegType && formData.kegType.value ? formData.kegType.value : undefined;
  if (isSuper()) formattedData.enableLightWakeup = formData.enableLightWakeup;
  if (isSuper() && formData.enableLightWakeup) formattedData.lightWakeupThreshold = formData.lightWakeupThreshold;
  if (isSuper() && !isEmpty(formData.maxApCount)) formattedData.maxApCount = formData.maxApCount;
  if (isSuper()) formattedData.mcc = formData.mcc === "" ? undefined : formData.mcc;
  if (isSuper() && !isEmpty(formData.minApCount)) formattedData.minApCount = formData.minApCount;
  if (isSuper()) formattedData.mnc = formData.mnc === "" ? undefined : formData.mnc;
  if (isSuper()) formattedData.enableMovementWakeup = formData.enableMovementWakeup;
  if (isSuper() && formData.enableMovementWakeup) formattedData.movementWakeupThreshold = formData.movementWakeupThreshold;
  if (isSuper() && !isEmpty(formData.networkRetryTime)) formattedData.networkRetryTime = formData.networkRetryTime;
  formattedData.nickname = trimString(formData.nickname);
  formattedData.notes = trimString(formData.notes);
  formattedData.trackerTypeId = formData.trackerTypeId;
  formattedData.showCoupled = formData.showCoupled;
  formattedData.showEngineRunning = formData.showEngineRunning;
  formattedData.showEvents = formData.showEvents;
  formattedData.showFreshness = formData.showFreshness;
  formattedData.showInput1 = formData.showInput1;
  formattedData.showInput2 = formData.showInput2;
  formattedData.showInput3 = formData.showInput3;
  formattedData.showInput4 = formData.showInput4;
  formattedData.showInput5 = formData.showInput5;
  formattedData.showInput6 = formData.showInput6;
  formattedData.showInput7 = formData.showInput7;
  formattedData.showLight = formData.showLight;
  formattedData.showMovement = formData.showMovement;
  formattedData.showNetwork = formData.showNetwork;
  formattedData.showOrientation = formData.showOrientation;
  formattedData.showSample = formData.showSample;
  formattedData.showSupplyVoltage = formData.showSupplyVoltage;
  formattedData.showTemperature = formData.showTemperature;
  formattedData.showTimeOfFlight = formData.showTimeOfFlight;
  formattedData.showVoltage = formData.showVoltage;
  formattedData.trackerTags = formData.trackerTags ? formData.trackerTags.map((tag: any) => tag.label) : [];
  if (formData.configurableUpload) {
    formattedData.sleepCount = formData.logsPerUpload !== "" ? formData.logsPerUpload : undefined;
    formattedData.sleepDuration =
      formData.logsPerUpload !== ""
        ? formData.uploadRateUnits && formData.uploadRateUnits.value === "minutes"
          ? formData.uploadRate / formData.logsPerUpload
          : (formData.uploadRate * 60) / formData.logsPerUpload
        : undefined;
  }
  if (isSuper()) formattedData.enableTemperatureWakeup = formData.enableTemperatureWakeup;
  if (isSuper() && formData.enableTemperatureWakeup) formattedData.temperatureWakeupThreshold = toCelsius(formData.temperatureWakeupThreshold);
  if (isBinaryBeer()) formattedData.uploadRate = formData.beerUploadRate && formData.beerUploadRate.value ? formData.beerUploadRate.value : undefined;

  return formattedData;
};

export default formatTrackerSettings;
