import React, { FC, useState, useEffect } from "react";
import RcSlider from "rc-slider";
import { FormInput, InputUnitWrapper } from "../FormComponents";

const Slider: FC<any> = ({ value, setValue, onChange, onAfterChange, minRange = 0, maxRange = 9999, unit = "", step = 1, hideNumberInput = false }) => {
  const [displayedValue, setDisplayedValue] = useState<any>(value);

  useEffect(() => {
    setDisplayedValue(value);
  }, [value]);

  const handleKeyDown = (e: any) => {
    // 13 = ENTER
    if (e.keyCode === 13) {
      handleBlur({ target: { value: displayedValue } });
    }
  };

  const handleBlur = (e: any) => {
    const val = e.target.value;
    const regex = /^[-]?[0-9\b]+$/;

    // set value if number else don't change
    if (val === "" || regex.test(val)) {
      const num = +val;
      if (num < minRange) {
        setValue(minRange);
        setDisplayedValue(minRange);
      } else if (num > maxRange) {
        setValue(maxRange);
        setDisplayedValue(maxRange);
      } else {
        setValue(num);
        setDisplayedValue(num);
      }
    } else {
      setValue(value);
      setDisplayedValue(value);
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        {!hideNumberInput && (
          <div style={{ paddingRight: "16px" }}>
            <div style={{ textAlign: "center" }}>
              <InputUnitWrapper unit={unit}>
                <FormInput
                  style={{ textAlign: "center" }}
                  type="text"
                  value={displayedValue}
                  onChange={(e: any) => {
                    setDisplayedValue(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </InputUnitWrapper>
            </div>
          </div>
        )}
        <RcSlider min={minRange} max={maxRange} value={value} step={step} onChange={onChange} onAfterChange={onAfterChange} />
      </div>
    </>
  );
};

export default Slider;
