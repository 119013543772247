import { ModalFormContainer } from "../Modal/styles";
import styled from "../Themes";
import qrIcon from "../../imgs/qrIcon.svg";

export const BatteryEstimateContainer = styled<any>(ModalFormContainer)`
  position: relative;
  margin: 5px 0 8px 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const QrScanButton = styled<any>(`div`)`
  display: block;
  position: relative;

  &::after {
    content: "";
    background-size: 20px 20px;
    background-image: url(${qrIcon});
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 0.5em;
    filter: ${({ theme }) => theme.filter_theme} !important;
  }
`;

export const UpdateImageButton = styled(`div`)`
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: ${({ theme }) => theme.color.modal_background[2]};
  fill: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
`;

export const UpdateImageButtonContainer = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.color.primary[2]};
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }
`;

export const CameraIconContainer = styled(`div`)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
`;
